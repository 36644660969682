








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DeletePrompt extends Vue {
  @Prop() public active = false;

  close() {
    this.$emit("close");
  }
}
